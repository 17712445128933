@import "../milligram/Color";
@import "../milligram/Variables";
/* header
----------------------------------------------------------------------------------------------*/
header {
  background: transparent;
  left: 0;
  position: absolute;
  right: 0;
  top: 44px;
  transition: 0.2s all ease;
  z-index: 997;
  &::before {
    background: #fff;
    content: "";
    height: 0;
    left: 0;
    position: absolute;
    top: 0;
    transition: 0.2s all ease;
    width: 100%;
    z-index: -1;
  }
  &.float {
    padding: 15px 0;
    position: fixed;
    top: 0;
    transition: 0.2s all ease;
    &::before {
      height: 100%;
      transition: 0.2s all ease;
      transition-delay: 0.15s;
    }
    nav ul li {
      &.search > img {
        content: url("/images/material/icon-search-grey.svg");
        transition-delay: 0.15s;
      }
      a {
        color: #282828;
        transition: 0.2s all ease;
        transition-delay: 0.15s;
        &:hover {
          color: $color-secondary;
          transition: 0.1s all ease;
        }
      }
    }
    .head-search > img {
      content: url("/images/material/icon-search-grey.svg");
      transition-delay: 0.15s;
    }
  }
  .wrapper {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  .logo {
    display: inline-block;
    transition: 0.2s all ease;
    img {
      display: block;
      margin: auto;
      height: auto;
      width: 120px;
    }
  }
  nav {
    position: relative;
    right: 48px;
    ul {
      align-items: center;
      display: flex;
      margin: 0;
      li {
        margin: 0 24px;
        a {
          color: #545454;
          display: block;
          font-family: $font-syne;
          font-size: 15px;
          text-transform: uppercase;
        }

        &.nav-lang {
          position: relative;

          .dropdown {
            display: none;
            position: absolute;
            top: 30px;
            left: 50%;
            transform: translateX(-50%);
            background: $color-initial;
            border-radius: 4px;
            box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
            transition: all 0.3s ease-out;

            ul {
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              width: 70px;
              padding: 2px 0;

              li {
                width: 100%;
                margin: 0;

                a {
                  color: #545454;
                  padding: 2px 16px;

                  @media (hover: hover) {
                    &:hover {
                      color: $color-secondary;
                    }
                  }

                  &.active {
                    color: $color-secondary;
                  }
                }
              }

              span {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  .menu-bar {
    display: none;
    position: relative;
  }
  .head-search {
    margin-right: 0;
    position: absolute;
    right: 0;
    z-index: 99;
    > img {
      cursor: pointer;
      display: block;
      margin: 0;
    }
    .search-box {
      background: $color-initial;
      border: 1px solid #dbdbdb;
      border-radius: 80px;
      display: none;
      height: 56px;
      min-height: 0;
      opacity: 0;
      padding: 0;
      position: absolute;
      right: 0;
      top: 140%;
      width: 620px;
      z-index: 99;
      form {
        height: 100%;
        margin-bottom: 0;
        position: relative;
        input[type="text"] {
          border: 0;
          border-radius: 80px;
          color: #333;
          font-size: 16px;
          line-height: 100%;
          height: 100%;
          margin-bottom: 0;
          padding: 0 70px 0 34px;
          position: relative;
          width: 100%;
        }
        button {
          background: transparent;
          border-color: transparent;
          border-radius: 0 80px 80px 0;
          color: #fff;
          cursor: pointer;
          height: 100%;
          margin-bottom: 0;
          padding: 0 10px;
          position: absolute;
          right: 0;
          text-align: center;
          top: 0;
          width: 60px;
          > img {
            display: block;
            margin: 0 auto;
            position: relative;
          }
        }
      }
    }
  }
}

.overlay {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));
  bottom: 0;
  content: "";
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 996;
}
/* footer
----------------------------------------------------------------------------------------------*/
footer {
  background: url("/images/content/background-footer.png") no-repeat top center,
    #fbfbfb;
  background-size: cover;
  padding: 125px 0 62px;
  position: relative;
  .wrapper {
    max-width: 996px;
  }
  .foot-title {
    margin: 0 auto 48px;
    text-align: center;
    width: 50%;
    h4 {
      color: $color-initial;
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 42px;
      margin-bottom: 16px;
      text-align: center;
      text-transform: uppercase;
    }
    p {
      color: $color-initial;
    }
  }
  .subscribe {
    border-bottom: 1px solid transparentize(#ffffff, 0.8);
    margin: 0 auto 64px;
    padding-bottom: 64px;
    &__form {
      align-items: center;
      display: flex;
      justify-content: center;
      input {
        background: #333333;
        border: 1px solid #333333;
        border-radius: 32px 0 0 32px;
        color: $color-initial;
        height: 48px;
        margin-bottom: 0;
        padding: 0 32px;
        width: 440px;
        &::placeholder {
          color: $color-initial;
          opacity: 0.5;
        }
        &::-moz-placeholder {
          color: $color-initial;
          opacity: 0.5;
        }
        &::-webkit-input-placeholder {
          color: $color-initial;
          opacity: 0.5;
        }
      }
      button {
        background: $color-secondary;
        border-radius: 0 32px 32px 0;
        border: 1px solid rgba(255, 255, 255, 0.1);
        color: $color-initial;
        height: 48px;
        margin-bottom: 0;
        padding: 0 32px;
        width: auto;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
  .foot-link {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 16px;
    a {
      color: $color-initial;
      display: block;
      font-family: $font-syne;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 17px;
      margin: 0 32px 32px;
      text-transform: uppercase;
      &:hover {
        color: $color-secondary;
      }
    }
  }
  .social-media {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
    a {
      display: block;
      margin: 0 6px;
      position: relative;
      transition: 0.2s all ease;
      &:hover {
        top: -3px;
        transition: 0.2s all ease;
      }
    }
  }
  .copyright {
    text-align: center;
    span {
      color: transparentize($color-initial, 0.5);
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      text-align: center;
      a {
        color: inherit;
        display: inline-block;
        &:hover {
          color: $color-initial;
        }
      }
    }
  }
}

/* responsive desktop
----------------------------------------------------------------------------------------------*/
@media all and (min-width: 1690px) {
  header nav ul li {
    &.search .search-box form input[type="text"],
    a {
      font-size: 1.125rem;
    }
  }
  footer {
    .wrapper {
      max-width: 1024px;
    }
    .foot-title h4 {
      font-size: 40px;
    }
    .subscribe__form {
      input,
      button {
        height: 58px;
      }
    }
    .foot-link a {
      font-size: 1rem;
    }
    .social-media a > img {
      height: 42px;
      width: auto;
    }
    .copyright span {
      font-size: 14px;
    }
  }
}

/* responsive tablet landscape
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1280px) {
  header {
    top: 35px;
    .logo img {
      height: 56px;
      width: auto;
    }
    nav {
      ul li {
        &.search img {
          position: relative;
          top: -2px;
        }
        a {
          font-size: 13px;
          line-height: 16px;
        }
      }
    }
  }
  footer {
    padding: 84px 0 40px;
    .wrapper {
      max-width: unset;
    }
    .foot-title h4 {
      font-size: 24px;
      line-height: 31px;
    }
    .foot-link a {
      font-size: 13px;
      line-height: 16px;
      margin: 0 24px 24px;
    }
  }
}

/* responsive tablet potrait
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1023px) {
  header {
    &.float {
      nav ul li a {
        color: $color-initial;
      }
      .menu-bar span {
        background: #a4a4a4;
      }
    }
    &.active {
      &.float::before {
        height: 0;
      }
      .logo {
        pointer-events: none;
        opacity: 0;
        transition: 0.2s all ease;
      }
    }
    nav {
      background: $color-primary;
      bottom: 0;
      margin-right: -508px;
      opacity: 0;
      padding: 59px 58px 59px 48px;
      position: fixed;
      right: 0;
      top: 0;
      transition: 0.2s all ease;
      visibility: hidden;
      width: 508px;
      z-index: 9999;
      &.active {
        margin-right: 0px;
        opacity: 1;
        transition: 0.2s all ease;
        visibility: visible;
      }
      ul {
        align-items: unset;
        flex-direction: column;
        li {
          margin: 0 0 24px 0;
          a {
            color: $color-initial;
            font-size: 16px;
            line-height: 28px;
          }

          &.nav-lang {
            position: relative;

            > a {
              display: none;
            }

            .dropdown {
              display: block;
              position: relative;
              top: unset;
              left: unset;
              transform: unset;
              background: transparent;
              border-radius: 0;
              box-shadow: none;
              transition: all 0.3s ease-out;

              ul {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-direction: row;
                width: auto;
                padding: 2px 0;

                li {
                  width: auto;
                  margin: 0;

                  a {
                    color: $color-initial;
                    padding: 0;
                  }
                }

                span {
                  display: inline-block;
                  color: $color-initial;
                  margin: 0 2px;
                }
              }
            }
          }
        }
      }
    }
    .head-search {
      right: 84px;
      .search-box {
        right: -76px;
        width: 480px;
      }
    }
    .menu-bar {
      align-items: flex-end;
      display: flex;
      flex-direction: column;
      padding: 8px;
      &.active {
        position: fixed;
        right: 530px;
        z-index: 999;
        span {
          width: 22px !important;
          transition: 0.2s all ease;
          &:first-child {
            transform: rotate(45deg);
            transform-origin: 5px 5px;
          }
          &:last-child {
            transform: rotate(-45deg);
            transform-origin: 6px -2px;
          }
        }
      }
      span {
        background: #a4a4a4;
        border-radius: 100px;
        display: block;
        height: 2px;
        margin: 4px 0;
        transition: 0.2s all ease;
        width: 32px;
        &:last-child {
          width: 24px;
        }
      }
    }
  }
  footer {
    .foot-title {
      width: 70%;
      h4 {
        font-size: 22px;
        line-height: 29px;
      }
    }
  }
}

/* responsive mobile
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 767px) {
  header {
    top: 0;
    padding: 16px 0;
    position: fixed;
    &::before {
      background: transparent;
      height: 100%;
    }
    &.float {
      padding: 16px 0;
      &::before {
        background: $color-initial;
      }
    }
    &.active::before {
      height: 0;
    }
    .logo img {
      height: 48px;
    }
    .head-search {
      > img {
        content: url("/images/material/icon-search-grey.svg");
        height: 24px;
        width: 24px;
      }
      .search-box {
        border-radius: 0;
        border: none;
        height: 80px;
        position: fixed;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 99;
      }
    }
    .menu-bar {
      &.active {
        right: 310px;
        span {
          background: $color-initial !important;
        }
      }
      span {
        background: #a4a4a4 !important;
      }
    }
    nav {
      padding: 40px 32px;
      width: 280px;
    }
  }
  footer {
    background: url("/images/content/background-footer-mobile.png") no-repeat
        top center,
      #fbfbfb;
    background-size: cover;
    padding: 96px 0 40px;
    .foot-title {
      margin-bottom: 40px;
      width: 100%;
      h4 {
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 8px;
      }
    }
    .subscribe {
      margin-bottom: 56px;
      padding-bottom: 56px;
      &__form {
        input {
          padding: 0 24px;
          width: 100%;
        }
        button {
          font-size: 13px;
          padding: 0 16px;
          width: auto;
        }
      }
    }
    .foot-link {
      column-count: 2;
      display: block;
      margin-bottom: 77px;
      a {
        margin: 0 32px 32px 0;
      }
    }
  }
}

@media (max-height: 440px) {
  header nav ul {
    max-height: 260px;
    overflow: auto;
  }
}
