.loading {
  background: #fff;
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  position: fixed;
  width: 100vw;
  z-index: 9999;
  &__img {
  }
  .copyright {
    bottom: 40px;
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
    z-index: 1;
    span {
      color: transparentize(#000, .5);
      display: block;
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      text-align: center;
      a {
        color: inherit;
        display: inline-block;
        &:hover {
          color: $color-initial;
        }
      }
    }
  }
}