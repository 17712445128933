@import "../milligram/Color";
@import "../milligram/Variables";

/* general styling
----------------------------------------------------------------------------------------------*/
.title-section {
  span {
    color: $color-primary;
    display: block;
    font-family: $font-syne;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.2em;
    margin-bottom: 12px;
    text-transform: uppercase;
  }
  h1, h2 {
    color: $color-primary;
    font-weight: 600;
    line-height: 52px;
    margin-bottom: 24px;
    text-transform: uppercase;
  }
  h3 {
    color: $color-primary;
    font-weight: 600;
    line-height: 42px;
    margin-bottom: 24px;
    text-transform: uppercase;
  }
  p {
    color: $color-quaternary;
  }
}

.std-content {
  > img,
  > figure > img {
    display: block;
    margin: 40px auto;
    height: auto;
    width: 100%;
  }
  ol {
		counter-reset: counter;
		list-style: none;
		margin: 0 0 20px;
		li {
			counter-increment: counter;
			padding-left: 30px;
			position: relative;
			&::before {
				color: inherit;
				content: counters(counter, ".") " ";
				display: flex;
				font-weight: normal;
				font-size: inherit;
				line-height: inherit;
				height: 28px;
				justify-content: left;
				left: 0;
				position: absolute;
				top: 0;
				width: 20px;
			}
		}
	}
	ul {
		list-style: none;
		margin: 0 0 20px;
    &.no-bullet li {
      padding-left: 0;
      &::before {
        display: none;
      }
    }
		li {
			padding-left: 30px;
			position: relative;
			&::before {
				background: $color-primary;
				border-radius: 50%;
				content: "";
				display: flex;
				font-weight: normal;
				font-size: inherit;
				line-height: inherit;
				height: 8px;
				justify-content: left;
				left: 0;
				position: absolute;
				top: 12px;
				width: 8px;
			}
			ul {
				margin-top: 15px;
			}
		}
	}
  span {
    display: block;
  }
}


.pagination {
	position: relative;
	padding-top: 40px;
	display: flex;
	justify-content: center;
	a {
		display: block;
		font-size: 1.125rem;
		color: #A4A4A4;
		transition: all ease-out .25s;
		margin: 0 18px;
		line-height: 24px;
		&.active {
			font-weight: 700;
			color: $color-primary;
			transition: all ease-out .25s;
		}
		&:hover {
			color: $color-primary;
			transition: all ease-out .25s;
		}
		&.prev,
		&.next {
			width: 24px;
			height: 24px;
			background: url('/images/material/arrow-selectbox.svg') no-repeat center;
			display: block;
			transform: rotate(-90deg);
			margin-left: 20px;
		}
		&.prev {
			transform: rotate(-270deg);
			margin-left: 0;
			margin-right: 20px;
		}
		&.disabled {
			opacity: .6;
			pointer-events: none;
		}
	}
}


/* responsive desktop
----------------------------------------------------------------------------------------------*/
@media (min-width: 1690px) {
  .title-section {
    span {
      font-size: 1rem;
    }
    h1, h2 {
      line-height: normal;
    }
    h3 {
      line-height: 1.4;
    }
  }
}
// @media all and (max-width: 1600px) {}



/* responsive tablet landscape
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1280px) {
  .title-section {
    span {
      margin-bottom: 16px;
    }
    h2, h3 {
      margin-bottom: 24px;
      font-size: 1.5rem;
      line-height: 1.29 !important;
    }
    p {
      margin-bottom: 40px;
    }
  }
}

@media all and (max-width: 1023px) {
  .title-section {
    span {
      line-height: 16px;
    }
    h2, h3 {
      font-size: 1.375rem;
      line-height: 1.29 !important;
    }
  }
}

@media all and (max-width: 1023px) {
  .title-section {
    span {
      line-height: 16px;
    }
    h2, h3 {
      font-size: 1.25rem;
      line-height: 1.3 !important;
    }
  }
}



/* responsive tablet potrait
----------------------------------------------------------------------------------------------*/
// @media all and (max-width: 1023px) {}



/* responsive mobile
----------------------------------------------------------------------------------------------*/
// @media all and (max-width: 767px) {}