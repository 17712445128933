/*=============================================================================================
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Your Name
==============================================================================================*/
/* helper
----------------------------------------------------------------------------------------------*/
// @import "../milligram/Color";    // import your general color from milligram

/* general
----------------------------------------------------------------------------------------------*/
@import "../component/layout";
@import "../component/loader";

@import "../milligram/Color";
@import "../milligram/Mixins";
@import "../milligram/Variables";
@import "general";

/* home styling
----------------------------------------------------------------------------------------------*/
header {
  nav ul li a {
    color: $color-initial;
  }
  .head-search > img {
    content: url("/images/material/icon-search.svg");
  }
}
.trigger {
  position: absolute;
  top: 0;
  height: 40%;
  bottom: 0;
  margin: auto;
  z-index: -1;
  left: 0;
  right: 0;

}
.banner {
  background: url("/images/content/background-banner.png") no-repeat center,
    #fbfbfb;
  background-size: cover;
  position: relative;
  height: 1023px;
  overflow: hidden;
  &.before-animate {
    .banner__wrapper-text {
      span,
      h1,
      p,
      a {
        opacity: 0;
      }
      @include anim-indicator(from, bottom, -20px, 0, "span", "h1", "p", "a");
    }
    .scroll-down {
      span {
        opacity: 0;
      }
      @include anim-indicator(from, left, -20px, 0, "span");
    }
  }
  &.after-animate {
    .banner__wrapper-text {
      @include anim-indicator(to, bottom, 0, 0.2, .4,"span", "h1", "p", "a");
    }
    .scroll-down {
      @include anim-indicator(to, left, 0, 0.2, .6,"span");
    }
  }
  figure {
    display: block;
    height: 1023px;
    // height: 100%;
    text-align: right;
    width: 100%;
    transform: scale(1);
    // transform: translateX(100%);
    .masking-img {
      mask-image: url("/images/material/mask-homepage.svg");
      mask-type: alpha;
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: inherit;
      -webkit-mask-position: 120%;
      mask-position: 120%;
      height: 100%;
      width: 100%;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.8) 0%,
        rgba(0, 0, 0, 0) 24.08%
      );
      object-fit: cover;
    }

    img {
      &.masking-img {
        display: none;
      }
    }
  }
  &__wrapper {
    height: 100vh;
    display: flex;
    align-items: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    &--flex {
      width: 100%;
      margin: auto 0;
      position: relative;
    }
    &-text {
      width: 40%;
      span {
        color: $color-primary;
        display: block;
        font-family: $font-syne;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.2em;
        margin-bottom: 16px;
        text-transform: uppercase;
      }
      h1,
      h2 {
        color: $color-primary;
        font-family: $font-syne;
        font-weight: 600;
        margin-bottom: 22px;
        text-transform: uppercase;
      }
      p {
        opacity: 0.7;
      }
    }
  }
  .scroll-down {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    span {
      color: $color-tertiary;
      cursor: pointer;
      display: inline-block;
      font-family: $font-syne;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.2em;
      margin-top: 110px;
      padding-bottom: 16px;
      position: relative;
      text-transform: uppercase;
      transform: rotate(-90deg);
      transform-origin: 0 0;
      &::before {
        background: transparentize($color-tertiary, 0.5);
        bottom: 0;
        content: "";
        height: 1px;
        left: 0;
        position: absolute;
        width: 71px;
      }
    }
  }
}
.about {
  background: #fbfbfb;
  padding: 56px 0 186px;
  position: relative;
  overflow: hidden;
  &::before {
    content: url("/images/content/background-about.png");
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 0;
  }
  &.before-animate {
    span,
    h2,
    h3 p,
    a {
      opacity: 0;
    }
    @include anim-indicator(from,bottom,-20px,0,"span","h2","h3","p","a");
  }
  &.after-animate {
    @include anim-indicator(to, bottom, 0, 0.1, .1, "span", "h2", "h3", "p", "a");
  }
  &__wrapper {
    display: flex;
    max-width: 1044px;
    &-left {
      flex: 0 0 45%;
      max-width: 45%;
      padding-left: 20px;
      padding-right: 44px;
    }
    &-right {
      flex: 0 0 55%;
      max-width: 55%;
      padding-left: 44px;
      p {
        opacity: 0.7;
      }
      .button {
        background: #fbfbfb;
        &.with-img::before {
          background: url("/images/material/icon-arrow-btn.svg") no-repeat
              center,
            #fbfbfb;
        }
      }
    }
  }
}
.product {
  background: url("/images/content/background-product.png") no-repeat top center,
    #fbfbfb;
  background-size: cover;
  padding: 148px 0 48px;
  position: relative;
  &.before-animate {
    .product__wrapper-left {
      span,
      h2,
      h3 p,
      a {
        opacity: 0;
      }
      @include anim-indicator(from, bottom, -20px, 0,"span","h2","h3","p","a");
    }
    .product__wrapper-right {
      @include anim-indicator(from, bottom, -20px, 0, 0, "figure > img");
    }
    .product__other-title {
      @include anim-indicator(from, bottom, -20px, 0, 0,"span", ".product__other-title--search");
    }
    .product__other-list {
      @include anim-indicator(from, bottom, -20px, 0, 0, ".product__other-list--item");
    }
    .text-center {
      a {
        opacity: 0;
      }
      @include anim-indicator(from, bottom, -20px, 0, "a");
    }
  }
  &.after-animate {
    .product__wrapper-left {
      @include anim-indicator(to, bottom, 0, 0.1, .2, "span", "h2", "h3", "p", "a");
    }
    .product__wrapper-right {
      @include anim-indicator(to, top, 0, 0.1, .2,"figure > img");
    }
    .product__other-title {
      @include anim-indicator(to, top,0,0.1,.2,"span",".product__other-title--search"
      );
    }
    .product__other-list--item {
      @include anim-listing(to, bottom, 0, 0.1, .7, 4);
    }
    .text-center {
      @include anim-indicator(to, top, 0, 0.1, 1.2, "a");
    }
  }
  &__wrapper {
    align-items: center;
    display: flex;
    max-width: 1044px;
    &-left {
      flex: 0 0 40%;
      max-width: 40%;
      padding-right: 63px;
      .title-section span {
        margin-bottom: 8px;
      }
      .button.with-img {
        background: #e6ecf5;
        &::before {
          background-color: #e6ecf5;
        }
      }
    }
    &-right {
      flex: 0 0 60%;
      max-width: 60%;
      text-align: center;
      figure {
        height: auto;
        width: 100%;
        > img {
          display: block;
          height: 100%;
          margin: auto;
          width: 100%;
          position: relative;
        }
      }
    }
  }
  &__other {
    margin-top: 34px;
    .wrapper {
      max-width: 1044px;
    }
    &-title {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
      span {
        color: $color-primary;
        display: block;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.2em;
        max-width: 50%;
        text-transform: uppercase;
      }
      &--search {
        max-width: 50%;
        > img {
          display: block;
          margin: auto;
        }
      }
    }
    &-list {
      display: flex;
      margin: 0 -33px 24px;
      &--item {
        flex: 0 0 calc(25% - 66px);
        margin: 0 33px;
        max-width: calc(25% - 66px);
        &:hover {
          figure > img {
            transform: scale(1.05);
            transition: 0.2s all ease;
          }
        }
        figure {
          display: block;
          text-align: center;
          > img {
            display: block;
            margin: 0 auto 16px;
            position: relative;
            transition: 0.2s all ease;
          }
          figcaption {
            color: $color-tertiary;
            font-family: $font-syne;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 22px;
            padding: 0 40px;
            text-align: center;
          }
        }
      }
    }
    .button.with-img {
      background: #fbfbfb;
      margin-top: 15px;
      &::before {
        background-color: #fbfbfb;
      }
    }
  }
}
.coverage {
  background: url("/images/content/background-coverage.png") no-repeat bottom
    center;
  background-size: cover;
  margin-bottom: 48px;
  padding: 80px 0 100px;
  position: relative;
  &.before-animate {
    .title-section {
      span,
      h2,
      h3 p,
      a {
        opacity: 0;
      }
      @include anim-indicator(from,bottom,-20px,0, 0"span","h2","h3","p","a");
    }
    @include anim-indicator(from,bottom, -20px, 0, 0, ".coverage__maps figure > img");
  }
  &.after-animate {
    .title-section {
      @include anim-indicator(to, bottom, 0, 0.1, .4, "span", "h2", "h3", "p", "a");
    }
    @include anim-indicator(to, top, 0, 0.1, .6, ".coverage__maps figure > img");
  }
  &__maps {
    .drag {
      overflow-x: scroll;
      figure {
        position: relative;
        left: 0 !important;
        > img {
          max-width: unset;
        }
        .pin {
          bottom: 0;
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
          z-index: 2;
          .marker {
            background: url(/images/material/icon-pin.svg) no-repeat top center;
            background-size: contain;
            cursor: pointer;
            height: 8px;
            position: absolute;
            text-align: center;
            width: 8px;
            span {
              position: absolute;
              color: #171717;
              font-weight: 700;
              font-size: 8px;
              line-height: 19px;
              text-transform: uppercase;
              transform: translateX(-50%);
              &.bottom {
                bottom: -15px;
              }
              &.top {
                top: -15px;
              }
              &.left {
                top: -6px;
                right: 12px;
                transform: unset;
              }
              &.right {
                top: -7px;
                left: 11px;
                transform: unset;
              }
            }
          }
        }
      }
    }
  }
}
.recipes {
  margin-bottom: 48px;
  padding: 40px 0;
  position: relative;
  &.before-animate {
    .recipes__wrapper-left {
      span,
      h2,
      p,
      a {
        opacity: 0;
      }
      @include anim-indicator(from, bottom, -20px, 0, 0,"span", "h2", "p", "a");
    }
    @include anim-indicator(from,bottom,-20px,0, 0,".recipes__wrapper-right figure img");
  }
  &.after-animate {
    .recipes__wrapper-left {
      @include anim-indicator(to, bottom, 0, 0.1, .3,"span", "h2", "p", "a");
    }
    @include anim-indicator(to,top,0,.1, .6,".recipes__wrapper-right figure img");
  }
  &__wrapper {
    align-items: center;
    display: flex;
    max-width: 1064px;
    &-left {
      flex: 0 0 50%;
      max-width: 50%;
      padding-right: 24px;
    }
    &-right {
      flex: 0 0 50%;
      max-width: 50%;
      padding-left: 24px;
      figure {
        overflow: hidden;
        img {
          display: block;
          height: 100%;
          margin: auto;
          object-fit: cover;
          object-position: center;
          width: 100%;
        }
      }
    }
  }
}
.inquiry {
  margin-bottom: -40px;
  padding-top: 46px;
  position: relative;
  &.before-animate {
    .inquiry__text {
      span,
      h2,
      p,
      a {
        opacity: 0;
      }
      @include anim-indicator(from, bottom, -20px, 0, 0, "span", "h2", "p", "a");
    }
  }
  &.after-animate {
    .inquiry__text {
      @include anim-indicator(to, bottom, 0, 0.1, .3,"span", "h2", "p", "a");
    }
  }
  > figure {
    position: relative;
    width: 47%;
    > img {
      display: block;
      height: auto;
      width: 100%;
    }
  }
  &__wrapper {
    max-width: calc(100vw - 1044px);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateY(-50%);
    width: 50%;
  }
  &__text {
    width: 120%;

    .title-section {
      h2.first {
        margin-bottom: 0 !important;
      }
    }
  }
}
footer {
  background-color: transparent;
}
/* responsive desktop
----------------------------------------------------------------------------------------------*/

// @media all and (max-width: 1903px) {
//   .banner > figure .masking-img {
//     -webkit-mask-position: 836px 10%;
//   }
// }

@media all and (min-width: 1480px) {
  .banner {
    height: 1240px;
    figure {
      height: 1240px;
      .masking-img {
        -webkit-mask-size: cover;
        mask-size: cover;
        -webkit-mask-position: 0;
        mask-position: 0;
        height: 100%;
        width: 57%;
      }
    }
  }
}

@media all and (min-width: 1690px) {
  .banner {
    &__wrapper-text span {
      font-size: 1rem;
      line-height: normal;
    }
    .scroll-down span {
      font-size: 16px;
      margin-top: 220px;
    }
  }
  .product {
    &__wrapper {
      &-left {
        flex: 0 0 45%;
        max-width: 45%;
        padding-right: 25px;
      }
      &-right {
        flex: 0 0 55%;
        max-width: 55%;
      }
    }
    &__other {
      &-title span {
        font-size: 1rem;
      }
      &-list--item figure figcaption {
        font-size: 20px;
      }
    }
  }
  .inquiry__text {
    width: 65%;
  }
}

// @media all and (max-width: 1480px) {
//   .banner > figure .masking-img {
//     -webkit-mask-position: 617px -156px;
//   }
// }

@media all and (max-width: 1366px) {
  .inquiry {
    &__wrapper {
      max-width: calc(100vw - 900px);
    }
    &__text {
      width: auto;
    }
  }
}

/* responsive desktop
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1600px) {
  .banner {
    overflow: hidden;
    p {
      max-width: 90%;
    }
  }
}

/* responsive tablet landscape
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1280px) {
  .banner {
    height: 910px;
    > figure {
      height: 910px;
      .masking-img {
        -webkit-mask-position: 0 0;
        mask-position: 0 0;
        -webkit-mask-size: cover;
        mask-size: inherit;
        width: 64%;
      }
    }
    &__wrapper-text {
      margin-bottom: 80px;
      h1,
      h2 {
        margin-bottom: 24px;
      }
    }
  }
  .about {
    margin-bottom: 0;
    padding: 150px 0 140px;
    &::before {
      transform: translateY(0) scale(0.7);
      transform-origin: left;
      top: -90px;
    }
    &__wrapper {
      margin: 0 80px;
      max-width: 100%;
      &-left {
        padding-left: 0;
        padding-right: 41px;
      }
      &-right {
        padding-left: 17px;
      }
    }
  }
  .product {
    padding: 82px 0 64px;
    &__wrapper {
      margin: 0 80px;
      max-width: unset;
      &-left {
        flex: 0 0 50%;
        max-width: 50%;
        padding-right: 24px;
        .title-section {
          span {
            margin-bottom: 16px;
          }
          h3 {
            width: 70%;
          }
          p {
            margin-bottom: 40px;
          }
        }
      }
      &-right {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
    &__other {
      .wrapper {
        margin: 0 80px;
        max-width: unset;
      }
      &-title {
        margin-bottom: 40px;
      }
      &-list {
        margin: 0 -22px 24px;
        &--item {
          flex: 0 0 calc(25% - 22px);
          max-width: calc(25% - 22px);
          margin: 0 11px;
        }
      }
    }
  }
  .coverage {
    padding: 88px 0 105px;
    &__maps figure .pin .marker {
      background-size: 24px 24px;
      padding-top: 28px;
    }
  }
  .recipes {
    margin-bottom: 0;
    padding: 130px 0;
    &__wrapper {
      margin: 0 80px;
      max-width: unset;
      &-left {
        padding-right: 12px;
      }
      &-right {
        padding-left: 12px;
      }
    }
  }
  .inquiry {
    padding-top: 0;
    &__wrapper {
      max-width: calc(100vw - 50% - 80px);
      margin-right: 40px;
    }
  }
}

/* responsive tablet potrait
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1023px) {
  header {
    .menu-bar span {
      background: $color-initial;
    }
  }
  .banner .scroll-down span {
    margin-top: 0;
  }
  .banner {
    // height: 587px;
    // > figure .masking-img {
    // mask-image: url("/images/material/mask-homepage-tablet-768.svg");
    // -webkit-mask-position: 356px -263px;
    // }
    &__wrapper {
      top: 161px;
      &-text {
        margin-bottom: 290px;
        width: 43%;
        p {
          width: 70%;
        }
      }
    }
  }
  .about {
    padding: 120px 0 86px;
    // &::before {
    //   left: -150px;
    // }
    &__wrapper {
      margin: 0 32px;
      &-left {
        flex: 0 0 50%;
        max-width: 50%;
      }
      &-right {
        flex: 0 0 50%;
        max-width: 50%;
        .button {
          margin-top: 20px;
        }
      }
    }
  }
  .product {
    background: url("/images/content/background-product-tablet2.png") no-repeat
        top center,
      #fbfbfb;
    background-size: contain;
    padding: 0;
    &__wrapper {
      margin: 0 32px;
      padding: 80px 0 187px;
      &-left {
        flex: 0 0 40%;
        max-width: 40%;
        .title-section h3 {
          width: 100%;
        }
      }
      &-right {
        flex: 0 0 60%;
        max-width: 60%;
      }
    }
    &__other {
      padding-bottom: 84px;
      .wrapper {
        margin: 0 32px;
      }
      &-list--item figure {
        > img {
          height: auto;
          width: 120px;
        }
        figcaption {
          font-size: 17px;
          line-height: 20px;
        }
      }
    }
  }
  .coverage {
    padding: 32px 0 77px;
    .wrapper {
      margin: 0 60px;
    }
    figure {
      left: 0;
    }
    .title-section {
      margin-bottom: 44px;
    }
    &__maps {
      overflow: scroll;
      figure {
        .pin {
          position: absolute;
          .marker {
            padding-top: 24px;
            position: absolute;
            span {
              font-size: 11px;
            }
          }
        }
      }
    }
  }
  .recipes {
    padding: 155px 0;
    &__wrapper {
      margin: 0 32px;
    }
  }
  .inquiry {
    padding: 70px 0;
    &__wrapper {
      max-width: calc(100vw - 50% - 32px);
      margin-left: 0;
    }
  }
}

/* responsive mobile
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 767px) {
  header {
    &:before {
      background: $color-initial;
    }
    .head-search {
      > img {
        content: url("/images/material/icon-search-grey.svg");
        height: 24px;
        width: 24px;
      }
    }
  }
  // .banner {
  //   height: 100vh;
  //   min-height: 600px;
  //   padding-top: 80px;
  //   > figure .masking-img {
  //     height: 100%;
  //     mask-image: none;
  //     object-fit: cover;
  //     object-position: center;
  //     width: 100%;
  //     margin-right: 0;
  //   }
  //   &__wrapper {
  //     top: 60%;
  //     transform: translateY(-50%);
  //     &-text {
  //       margin-bottom: 0;
  //       text-align: center;
  //       width: auto;
  //       * {
  //         color: $color-initial !important;
  //       }
  //       span {
  //         line-height: 16px;
  //       }
  //       p {
  //         width: auto;
  //       }
  //     }
  //   }
  //   .scroll-down {
  //     bottom: 0;
  //     position: relative;
  //     text-align: center;
  //     span {
  //       color: $color-initial;
  //       transform: unset;
  //       transform-origin: unset;
  //       padding: 48px 0 0;
  //       &::before {
  //         background: rgba($color: $color-initial, $alpha: 0.5);
  //         bottom: unset;
  //         height: 32px;
  //         margin: 0 auto;
  //         right: 0;
  //         top: 0;
  //         width: 1px;
  //       }
  //     }
  //   }
  // }
  .banner {
    height: 100vh;
    > figure {
      position: relative;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000;
        opacity: 0.3;
      }
      .masking-img {
        -webkit-mask-image: unset;
        width: 100%;
      }

      video {
        &.masking-img {
          display: none;
        }
      }

      img {
        &.masking-img {
          display: unset;
          object-position: right;
        }
      }
    }
    &__wrapper {
      top: 0;
      &-text {
        width: 100%;
        text-align: center;
        margin-bottom: 0;
        h1,
        h2,
        span {
          color: $color-initial;
        }
        p {
          color: $color-initial;
          max-width: 100%;
          width: 100%;
        }
      }
    }
    .scroll-down {
      text-align: center;
      span {
        &:before {
          background: $color-initial;
          width: 1px;
          height: 32px;
          left: 53px;
          top: -45px;
        }
        color: $color-initial;
        transform: unset;
        padding-bottom: 24px;
      }
    }
  }
  .about {
    padding: 81px 0;
    // &::before {
    //   left: -50px;
    //   transform: translateY(0) scale(0.3);
    //   top: -120px;
    // }
    &__wrapper {
      flex-wrap: wrap;
      margin: 0 16px;
      &-left {
        flex: 0 0 100%;
        max-width: 100%;
        padding-right: 0;
        .title-section {
          span {
            margin-bottom: 8px;
          }
          h3 {
            margin-bottom: 34px;
          }
        }
      }
      &-right {
        flex: 0 0 100%;
        max-width: 100%;
        padding-left: 0;
      }
    }
  }
  .product {
    background: url("/images/content/background-product-mobile.png") no-repeat
        top center,
      #fbfbfb;
    background-size: cover;
    &__wrapper {
      flex-wrap: wrap;
      margin: 0 16px;
      padding: 40px 0;
      &-left {
        flex: 0 0 100%;
        max-width: 100%;
        order: 2;
        .title-section {
          span {
            margin-bottom: 8px;
          }
          h3 {
            margin-bottom: 16px;
          }
        }
      }
      &-right {
        flex: 0 0 100%;
        max-width: 100%;
        order: 1;
        figure {
          margin-bottom: 32px;
          > img {
            height: 175px;
            width: auto;
          }
        }
      }
    }
    &__other {
      margin: 0;
      padding: 40px 0;
      .wrapper {
        margin: 0 16px;
      }
      &-list {
        flex-wrap: wrap;
        margin: 0 0 24px;
        &--item {
          flex: 0 0 100%;
          margin: 0 0 56px;
          max-width: 50%;
        }
      }
    }
  }
  .coverage {
    margin-bottom: 0;
    padding: 108px 0 160px;
    position: relative;
    &::before {
      background: linear-gradient(
        180deg,
        #fbfbfb 0%,
        rgba(251, 251, 251, 0) 100%
      );
      content: "";
      height: 200px;
      left: 0px;
      position: absolute;
      right: 0;
      top: 0px;
      width: 100%;
    }
    .wrapper {
      margin: 0 16px;
    }
    &__maps-box {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      height: 140px;
    }
    &__maps {
      overflow: inherit;
      transform: scale(0.28);
      transform-origin: 50% 0;
    }
  }
  .recipes {
    padding: 65px 0;
    &__wrapper {
      flex-wrap: wrap;
      margin: 0 16px;
      &-left {
        flex: 0 0 100%;
        max-width: 100%;
        order: 2;
        .title-section h2 {
          margin-bottom: 16px;
        }
      }
      &-right {
        flex: 0 0 100%;
        margin-bottom: 40px;
        max-width: 100%;
        order: 1;
      }
    }
  }
  .inquiry {
    padding: 0 0 70px;
    > figure {
      margin: 0 16px;
      width: auto;
    }
    &__wrapper {
      margin: 0 16px;
      max-width: unset;
      position: unset;
      transform: unset;
      width: auto;
      .title-section {
        h2.first {
          margin-bottom: 16px;
        }
        p {
          width: 90%;
        }
      }
    }
  }
}

@media (max-height: 440px) {
  .product {
    background-size: 100% 37%;
  }
}
